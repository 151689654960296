<template>
  <VContent>
    <template slot="right">
      <h6>{{ title }}</h6>
    </template>
    <template slot="left">
      <b-button size="sm" variant="success" class="ml-3" @click="update()"
        >تحديث</b-button
      >
    </template>
    <template slot="content">
      <VTextEditor v-model="form.text"></VTextEditor>
    </template>
  </VContent>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  data() {
    return {
      id: null,
      title: null,
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.about.form;
      },
      set(value) {
        this.$store.commit("banks/about/SET_TEXT", value);
      },
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.id = this.$route.meta.id;
      this.title = this.$route.name;
      this.$store.dispatch("banks/about/get", this.id);
    },
    update() {
      this.$store
        .dispatch("banks/about/update", this.id)
        .then((response) => {
          successAlert();
          this.$store.commit("banks/about/SET_TEXT", response.payload);
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>
<style></style>
